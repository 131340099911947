<template>
<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="home-tab mb-4">
                <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link ps-0" :class="{'active': aba == 'incluir'}" id="home-tab" data-bs-toggle="tab" href="#incluir" @click="abaTipo('incluir')" role="tab" aria-controls="incluir" :aria-selected="aba == 'incluir'">Produto</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" :class="{'active': aba == 'listagem'}" id="profile-tab" data-bs-toggle="tab" href="#listagem" @click="abaTipo('listagem')" role="tab" :aria-selected="aba == 'listagem'">Listagem</a>
                    </li>
                  </ul>
                </div>
            </div>
            <div v-if="aba == 'incluir'" class="col-12 grid-margin stretch-card">                
                <div class="card">                    
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-body">
                            <h4 class="card-title">Foto Produto</h4>
                            <p class="card-description">Faça upload da foto</p>
                                <div>
                                    <img v-show="!imagemLoading" @load="loadImagem" width="100%" :src="produto.imagemUpload"/>

                                    <div v-if="imagemLoading" class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    </div>

                                </div>
                                <div class="text-center mt-3">
                                    <input type="file" id="upload" @change="escolherArquivo" ref="file"  accept="image/*" hidden/>
                                    <label class="upload" for="upload">Selecionar Imagem</label>
                                </div>
                                <div class="text-center">
                                     <button type="button" @click="resetar" class="btn btn-danger btn-sm mx-2 mt-3">
                                                Remover
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                            <h4 class="card-title">Informações</h4>
                            <p class="card-description">Insira as informações básicas</p>
                            <div>
                                <form class="forms-sample">
                                    <div class="form-group">
                                        <label for="nome">Nome</label>
                                        <input type="text" v-model="produto.nome" @keyup="slugUrl" class="form-control" id="nome" />
                                    <div v-if="erros.descricao"  class="invalid-feedback" >
                                        {{erros.descricao}}
                                    </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="nome">Url Amigável</label>
                                        <input type="text" v-model="produto.urlAmigavel" class="form-control" id="url_amigavel" />
                                        <div v-if="erros.url_amigavel"  class="invalid-feedback" >
                                            {{erros.url_amigavel}}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="descricao">Informativo (Descrição)</label>
                                        <textarea class="form-control" v-model="produto.descricao" id="descricao" rows="6" style="height: 6rem;"></textarea>
                                    <div v-if="erros.informativo"  class="invalid-feedback" >
                                        {{erros.informativo}}
                                    </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="valor">Valor</label>
                                        <money class="form-control" v-model="produto.valor" v-bind="money"></money>
                                    <div v-if="erros.valor_unitario"  class="invalid-feedback" >
                                        {{erros.valor_unitario}}
                                    </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="valor">Categoria</label>
                                        <select v-model="produto.categoria" class="form-select" aria-label="Default select example">
                                            <option :value="item.id" v-for="item in categorias" :key="item.id">{{item.descricao}}</option>
                                        </select>
                                    <div v-if="erros.id_categoria"  class="invalid-feedback" >
                                        {{erros.id_categoria}}
                                    </div>
                                    </div>
                                    <button v-if="!modoEdicao" type="submit" @click.prevent="incluir" class="btn btn-success me-2 float-end mb-4">Incluir</button>
                                    <button v-if="modoEdicao" type="submit" @click.prevent="salvar" class="btn btn-primary me-2 float-end mb-4">Salvar</button>
                                    <button v-if="modoEdicao" type="submit" @click.prevent="cancelar" class="btn btn-danger me-2 float-end mb-4">Cancelar</button>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="aba == 'listagem'" class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Meus Produtos</h4>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    <tr v-for="(item, index) in produtos" :key="index">
                                        <td>{{++index}}º</td>
                                        <td style="max-width: 300px;white-space: pre-wrap;">{{item.descricao}}</td>
                                        <td>{{item.categoria}}</td>
                                        <td>{{item.valor_unitario.formatado}}</td>
                                        <td>
                                            <div style="float: right;">
                                                <button type="button" @click="editar(item)" class="btn btn-primary btn-sm mx-2 largura-botao">
                                                    Editar
                                                </button>

                                                <button @click="desativar(item.id)" v-if="item.ativo" type="button" class="btn btn-dark btn-sm mx-2 largura-botao">
                                                    Desativar
                                                </button>

                                                <button @click="ativar(item.id)" v-if="!item.ativo" type="button" class="btn btn-warning btn-sm mx-2 largura-botao">
                                                    Ativar
                                                </button>

                                                <button @click="deletar(item.id)" type="button" class="btn btn-danger btn-sm mx-2 largura-botao">
                                                    Deletar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axiosApi from '@/axios'
import store from '@/store'
import {Money} from 'v-money'
import slug from 'limax';

export default {
    name: 'Produtos',

    components: {Money},

    data(){
        return{
            imagemLoading: false,
            aba: 'incluir',
            modoEdicao: false,
            imagem: null,
            categorias: [],
            produtos: [],
            erros: [],
            produto: {
                id: null,
                nome: '',
                urlAmigavel: '',
                descricao: '',
                categoria: '',
                valor: 0.00,
                imagemUpload: 'https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg',
                arquivo: 'sem_imagem.jpg'
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            }
        }
    },

    async mounted(){
        try {
            store.commit('setLoading', true)
            await this.listarCategorias()
            await this.listarProdutos()        
        } finally {
            store.commit('setLoading', false)
        }
    },

    methods:{
        loadImagem(){
            this.imagemLoading = false
        },

        abaTipo(tipo){
            this.aba = tipo
        },

        slugUrl(){
            this.produto.urlAmigavel = slug(this.produto.nome, {lang: 'pt-br'})
        },

        resetar(){
            this.produto.imagemUpload = 'https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg' 
        },

        async listarCategorias() {
            const response = await axiosApi.get('/admin/categoria')
            this.categorias = response.data.itens
        },

        async desativar(id){
            try {
                store.commit('setLoading', true)
                let dados = {'ativo': false}
                await axiosApi.put(`/admin/produto/${id}`, dados)
                await this.listarProdutos()
            } finally {
                store.commit('setLoading', false)
            }
        },

        async deletar(id){
            try {
                store.commit('setLoading', true)
                await axiosApi.delete(`/admin/produto/${id}`)
                await this.listarProdutos()
            } catch(error){
                this.$swal('Deletar', error.response.data.erro, 'error')
            } finally {
                store.commit('setLoading', false)
            }
        },

        async ativar(id){
            try {
                store.commit('setLoading', true)
                let dados = {'ativo': true}
                await axiosApi.put(`/admin/produto/${id}`, dados)
                await this.listarProdutos()
            } finally {
                store.commit('setLoading', false)
            }
        },

        editar(produto){
            this.imagemLoading = true
            store.commit('setLoading', true)
            window.scrollTo(0,0)
            this.aba = 'incluir'
            this.erros = []
            this.modoEdicao = true
            this.produto.id = produto.id
            this.produto.nome = produto.descricao
            this.produto.urlAmigavel = produto.url_amigavel
            this.produto.valor = produto.valor_unitario.valor
            this.produto.categoria = produto.id_categoria
            this.produto.descricao = produto.informativo
            this.produto.imagemUpload = produto.url_midia ? produto.url_midia : 'https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg'
            this.produto.arquivo = produto.arquivo ? produto.arquivo : 'sem_imagem.jpg'
            this.produto.ativo = produto.ativo
            store.commit('setLoading', false)
        },

        cancelar(){
            this.modoEdicao = false
            this.novoProduto()
            window.scrollTo(0,0)
        },

        novoProduto(){
            this.aba = 'incluir'
            this.modoEdicao = false
            this.imagem = null
            this.categoria = ''
            this.erros = []
            this.produto = {
                nome: '',
                urlAmigavel: '',
                descricao: '',
                categoria: '',
                valor: 0.00,
                imagemUpload: 'https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg',
                arquivo: 'sem_imagem.jpg',
                ativo: false
            }
        },

        async listarProdutos() {
            const response = await axiosApi.get('/admin/produto')
            this.produtos = response.data.itens
        },

        async incluir(){
            try {
                store.commit('setLoading', true)
                let dados = {
                    'descricao': this.produto.nome,
                    'url_amigavel': this.produto.urlAmigavel,
                    'valor_unitario': this.produto.valor,
                    'id_categoria': this.produto.categoria,
                    'informativo': this.produto.descricao,
                    'url_midia': this.produto.imagemUpload,
                    'arquivo': this.produto.arquivo,
                    'ativo': true   
                }
                await axiosApi.post('/admin/produto', dados)
                await this.listarProdutos()
                this.novoProduto()
                this.$swal('Sucesso', 'Produto cadastrado com sucesso', 'success')
            } catch (error){
                this.erros = error.response.data.erros
            } finally {
                window.scrollTo(0,0)
                store.commit('setLoading', false)
            }
        },

        async salvar(){
           try {
                store.commit('setLoading', true)
                let dados = {
                    'descricao': this.produto.nome,
                    'url_amigavel': this.produto.urlAmigavel,
                    'valor_unitario': this.produto.valor,
                    'id_categoria': this.produto.categoria,
                    'informativo': this.produto.descricao,
                    'url_midia': this.produto.imagemUpload,
                    'arquivo': this.produto.arquivo,
                    'ativo': this.produto.ativo   
                }

                await axiosApi.put(`/admin/produto/${this.produto.id}`, dados)
                await this.listarProdutos()
                this.novoProduto()
                this.aba = 'listagem'  
                this.$swal('Sucesso', 'Produto editado com sucesso', 'success')
            } catch (error){
                this.erros = error.response.data.erros
            } finally {
                window.scrollTo(0,0)
                store.commit('setLoading', false)
            }
        },

        escolherArquivo(){
            this.imagem = this.$refs.file.files[0]

            const formData = new FormData()
            formData.append('foto_produto', this.imagem)
            const headers = {'Content-Type': 'multipart/form-data' }

            store.commit('setLoading', true)
            axiosApi.post('/admin/produto/upload', formData, { headers }).then((response) => {
                this.imagemLoading = true
                this.produto.imagemUpload = response.data.link
                this.produto.arquivo = response.data.arquivo
            }) .catch( (error) => {
                this.$swal('Upload', error.response.data.erro, 'error')
            }) .then(()=>{
                store.commit('setLoading', false)
            })
        }
    }
};
</script>

<style>
    label.upload{
        display: inline-block;
        background-color: #1f3bb3;
        color: white;
        padding: 0.5rem;
        font-family: sans-serif;
        border-radius: 0.3rem;
        cursor: pointer;
        margin-top: 1rem;
        font-size: 12px;
    }
    .invalid-feedback {
        display: block;
        font-size: 12px;
    }
    .largura-botao{
        min-width: 100px;
    }
</style>